import { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { emailValidation, passwordValidation } from "../../utils/validations";
import * as yup from "yup";
import AuthContext from "../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import BootstrapPassword from "../../components/Inputs/BootstrapPassword";

Amplify.configure({
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_CLIENT_ID,
});

const LoginPage = () => {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [t] = useTranslation();

  const schema = yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    authCtx.loginLoadingOp.start();
    try {
      const user = await Auth.signIn(data.email, data.password);
      authCtx.onLogin(user);
      navigate("/", { replace: true });
    } catch (error: any) {
      setError(error?.message);
    } finally {
      setLoading(false);
      authCtx.loginLoadingOp.finish();
    }
    // step 1
    // const user = await Auth.signUp({
    //   username: "p.zangad@ordermade.jp",
    //   password: "Aw123456!",
    //   attributes: {
    //     email: "p.zangad@ordermade.jp",
    //     preferred_username: "p.zangad@ordermade.jp",
    //   },
    // });
    // step 2
    // await Auth.confirmSignUp("p.zangad@ordermade.jp", "108482");
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) navigate("/");
  }, [authCtx.isLoggedIn]);

  return (
    <Fragment>
      <Box
        sx={{
          paddingTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          アカウントにサインインする
        </Typography>
      </Box>

      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3 }}
      >
        <Grid item xs={12}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel shrink htmlFor="email" sx={{ paddingY: 1 }}>
                  メール
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  type="email"
                  id="email"
                  error={!!errors?.email?.message}
                />
              </FormControl>
            )}
            name="email"
            control={control}
          />
          {!!errors?.email?.message && (
            <FormHelperText error id="email_helper">
              <>{errors?.email?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={({ field }) => {
              return (
                <FormControl sx={{ my: 1, width: 1 }}>
                  <InputLabel
                    shrink
                    htmlFor="password"
                    sx={{
                      transform: "none",
                      fontSize: "12px",
                    }}
                  >
                    パスワード
                  </InputLabel>
                  <BootstrapPassword
                    field={field}
                    sx={{ width: 1 }}
                    id="password"
                    placeholder="パスワードを入力してください"
                  />
                </FormControl>
              );
            }}
            name="password"
            control={control}
          />
          {!!errors?.password?.message && (
            <FormHelperText error id="password_helper">
              <>{errors?.password?.message}</>
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} pb={2}>
          <ButtonSubmit
            type="submit"
            sx={{ my: 3, width: 1, py: 1, fontWeight: 600 }}
            variant="contained"
            disabled={loading}
          >
            ログイン
          </ButtonSubmit>
          <Box textAlign={"center"}>
            <Link
              to="/forgot-password"
              style={{
                color: "#959DFD",
                textDecoration: "none",
                fontSize: "0.8rem",
              }}
            >
              パスワードをお忘れですか？
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} pb={2}>
          {error && (
            <Box
              sx={{
                width: 1,
                paddingY: 1,
                border: 1,
                color: "#9f1831",
                borderColor: "#9f1831",
                borderBlockColor: "#9f1831",
              }}
            >
              <Typography align="center">{t(error)}</Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default LoginPage;
