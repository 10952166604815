import React from "react";

const Home = () => {
  return (
    <div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus
      magnam sequi non ratione. Obcaecati distinctio atque, exercitationem
      impedit a, similique ut dolorem aliquid vel commodi laudantium molestias
      quidem, blanditiis sapiente!
    </div>
  );
};

export default Home;
