import { useContext, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Container,
  createTheme,
  CssBaseline,
  IconButton,
  List,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Menu, Notifications, ChevronLeft } from "@mui/icons-material";
import MenuItems from "./MenuItems";
import { LOGO } from "../../assets";
import AuthContext from "../../custom-hooks/use-auth-context";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "#f5f5f5",
  color: "#000",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: "#9f1831",
    color: "#ffffff",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();

const Layout = () => {
  let isCollapsedMenu = localStorage.getItem("isCollapsedMenu");
  const [open, setOpen] = useState<boolean>(
    isCollapsedMenu && JSON.parse(isCollapsedMenu) === true ? true : false
  );
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const handleOpen = (value: boolean) => {
    localStorage.setItem("isCollapsedMenu", JSON.stringify(value));
  };

  const toggleDrawer = () => {
    setOpen(!open);
    handleOpen(!open);
  };

  useEffect(() => {
    if (!authCtx?.isLoggedIn) navigate("/login");
  }, [authCtx?.user?.id]);

  useEffect(() => {
    if (!authCtx?.userDevice) return;
    if (authCtx?.userDevice === "mobile") {
      setOpen(false);
      handleOpen(false);
    }
  }, [authCtx?.userDevice]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <Menu />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <Notifications />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
            }}
          >
            <Box sx={{ ml: 1 }}>
              <img src={LOGO} width={35} height={35} />
            </Box>

            <IconButton onClick={toggleDrawer} sx={{ color: "#fff" }}>
              <ChevronLeft />
            </IconButton>
          </Toolbar>
          <List component="nav">
            <MenuItems open={open} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box sx={{ mt: 4, mb: 4, px: 2 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;
