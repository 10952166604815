import { Select, styled } from "@mui/material";

const CustomSelect = styled(Select)(({ theme }) => ({
  ".Mui-focused": {
    boxShadow: "rgba(25, 118, 210, 0.25) 0 0 0 0.2rem",
    borderColor: "#1976d2",
  },
}));

export default CustomSelect;
