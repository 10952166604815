import { Fragment } from "react";
import {
  Button,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { CustomerType } from "../../../models";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

interface ConfirmationFormProps {
  formValues: Partial<CustomerType>;
  errors: any;
  handleBack: () => void;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  formValues,
  errors,
  handleBack,
}) => {
  return (
    <Fragment>
      {/* error list */}
      <Grid>
        <List
          sx={{
            listStyleType: "disc",
            listStylePosition: "inside",
            color: "red",
            fontSize: "0.75rem",
          }}
        >
          {Object.keys(errors).map((err) => (
            <ListItem sx={{ display: "list-item" }}>
              <>{errors[err]?.message}</>
            </ListItem>
          ))}
        </List>
      </Grid>
      {/* title of form */}
      <Grid>
        <Typography
          sx={{ fontWeight: 550, fontSize: "1.2em", color: "#4B4A4A" }}
        >
          確認及び作成
        </Typography>
        <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
          情報が合致しているかどうか確認してください。
        </Typography>
      </Grid>
      {/* content */}
      <Grid my={2}>
        {/* アカウント情報 */}
        <Grid my={2}>
          <Grid my={1}>
            <Typography
              sx={{ color: "#4B4A4A", fontWeight: 550, fontSize: "0.9em" }}
            >
              アカウント情報
            </Typography>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                カスタマー名
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                }}
              >
                {formValues.customer_name || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                メールアドレス
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                {formValues.customer_email || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* カスタマー情報 */}
        <Grid my={2}>
          <Grid my={1}>
            <Typography
              sx={{ color: "#4B4A4A", fontWeight: 550, fontSize: "0.9em" }}
            >
              カスタマー情報
            </Typography>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                ご担当者様氏名
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                }}
              >
                {formValues.contact_person_name || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                住所
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                {(formValues.postal_code && formValues.prefectures && (
                  <>
                    {formValues.postal_code} {formValues.prefectures}
                  </>
                )) ||
                  "-"}
              </Typography>
            </Grid>
          </Grid>
          {formValues.municipalities && (
            <Grid container spacing={2} py={0.5}>
              <Grid item sm={4}></Grid>
              <Grid item sm={8}>
                <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                  {formValues.municipalities}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                電話番号
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                {formValues.phone_number || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* 店舗情報 */}
        <Grid my={2}>
          <Grid my={1}>
            <Typography
              sx={{ color: "#4B4A4A", fontWeight: 550, fontSize: "0.9em" }}
            >
              店舗情報
            </Typography>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                店舗名
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                }}
              >
                {formValues.store_name || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                サブ店舗名
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                {formValues.sub_store_name || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                店舗形態
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                {formValues.store_type || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} py={0.5}>
            <Grid item sm={4}>
              <Typography
                sx={{
                  color: "#4B4A4A",
                  fontSize: "0.75em",
                  textIndent: "15px",
                }}
              >
                店舗所在地
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                {(formValues.store_postal_code &&
                  formValues.store_prefectures && (
                    <>
                      {formValues.store_postal_code}{" "}
                      {formValues.store_prefectures}
                    </>
                  )) ||
                  "-"}
              </Typography>
            </Grid>
          </Grid>
          {formValues.store_municipalities && (
            <Grid container spacing={2} py={0.5}>
              <Grid item sm={4}></Grid>
              <Grid item sm={8}>
                <Typography sx={{ color: "#4B4A4A", fontSize: "0.75em" }}>
                  {formValues.store_municipalities}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* ご契約のまっせ */}
        <Grid my={2}>
          <Grid my={1}>
            <Typography
              sx={{ color: "#4B4A4A", fontWeight: 550, fontSize: "0.9em" }}
            >
              ご契約のまっせ
            </Typography>
          </Grid>
          <Grid container my={1}>
            {formValues.nomasses &&
              formValues.nomasses.map((value, index) => {
                return (
                  <Grid item sm={"auto"} mx={1}>
                    <Typography
                      sx={{ fontSize: "0.75em", textTransform: "uppercase" }}
                    >
                      {value}
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      {/* Submit button */}
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          type="button"
          onClick={handleBack}
          sx={{ mt: 1, mr: 1, color: "#4B4A4A" }}
        >
          戻る
        </Button>
        <ButtonSubmit
          type={"submit"}
          sx={{ mt: 1, mr: 1 }}
        >
          登録する
        </ButtonSubmit>
      </Grid>
    </Fragment>
  );
};

export default ConfirmationForm;
