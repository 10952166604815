import { Fragment } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { AccountFormType } from "../../../models";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapInput from "../../Inputs/BootstrapInput";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import {
  contactPersonNameValidation,
  customerNameValidation,
  emailValidation,
  municipalitiesValidation,
  phoneNumberValidation,
  postalCodeValidation,
  prefecturesValidation,
} from "../../../utils/validations";

interface AccountFormProps {
  preValues: AccountFormType;
  handleBack: () => void;
  onSubmit: (data: FieldValues) => void;
}

const AccountForm: React.FC<AccountFormProps> = ({
  preValues,
  handleBack,
  onSubmit,
}) => {
  const schema = Yup.object().shape({
    customer_name: customerNameValidation,
    customer_email: emailValidation,
    contact_person_name: contactPersonNameValidation,
    postal_code: postalCodeValidation,
    prefectures: prefecturesValidation,
    municipalities: municipalitiesValidation,
    phone_number: phoneNumberValidation,
  });

  const defaultValues: AccountFormType = preValues;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* start of account form */}
      <Fragment>
        <Typography
          sx={{ fontWeight: 550, fontSize: "1.2em", color: "#4B4A4A" }}
        >
          アカウント情報
        </Typography>
        <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
          カスタマーに共有するアカウントの情報です。パスワードは自動生成されます。
        </Typography>
        <Grid my={4}>
          <Grid container spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <Typography sx={{ fontSize: "1em", lineHeight: "2em" }}>
                カスタマー名
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      size="small"
                      error={!!errors?.customer_name?.message}
                    />
                  </FormControl>
                )}
                name="customer_name"
                control={control}
              />
              {!!errors?.customer_name?.message && (
                <FormHelperText error id="customer_name_helper">
                  <>{errors?.customer_name?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <Typography sx={{ fontSize: "1em", lineHeight: "2em" }}>
                メールアドレス
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...field}
                      fullWidth
                      type="email"
                      size="small"
                      error={!!errors?.customer_email?.message}
                    />
                  </FormControl>
                )}
                name="customer_email"
                control={control}
              />
              {!!errors?.customer_email?.message && (
                <FormHelperText error id="customer_email_helper">
                  <>{errors?.customer_email?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
      {/* end of account form */}
      {/* start of customer form */}
      <Fragment>
        <Grid my={4}>
          <Typography
            sx={{
              fontWeight: 550,
              fontSize: "1.2em",
              color: "#4B4A4A",
            }}
          >
            カスタマー情報
          </Typography>
          <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
            カスタマーに関する情報です。
          </Typography>
        </Grid>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={4}>
            <Typography sx={{ fontSize: "1rem", lineHeight: "2em" }}>
              ご担当者様氏名
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    size="small"
                    error={!!errors?.contact_person_name?.message}
                  />
                </FormControl>
              )}
              name="contact_person_name"
              control={control}
            />
            {!!errors?.contact_person_name?.message && (
              <FormHelperText error id="contact_person_name_helper">
                <>{errors?.contact_person_name?.message}</>
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={4}>
            <Typography sx={{ fontSize: "1rem", lineHeight: "2em" }}>
              住所
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Grid container spacing={2}>
              <Grid item sm={5}>
                <Controller
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={!!errors?.postal_code?.message}
                    >
                      <InputLabel
                        shrink
                        htmlFor="postal_code"
                        sx={{ paddingY: 1 }}
                      >
                        <Typography>郵便番号</Typography>
                      </InputLabel>
                      <BootstrapInput
                        {...field}
                        sx={{ width: 1 }}
                        type="text"
                        id="postal_code"
                        size="small"
                        error={!!errors?.postal_code?.message}
                      />
                    </FormControl>
                  )}
                  name="postal_code"
                  control={control}
                />
                {!!errors?.postal_code?.message && (
                  <FormHelperText error id="postal_code_helper">
                    <>{errors?.postal_code?.message}</>
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={7}>
                <Controller
                  render={({ field }) => (
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={!!errors?.prefectures?.message}
                    >
                      <InputLabel
                        shrink
                        htmlFor="prefectures"
                        sx={{ paddingY: 1 }}
                      >
                        都道府県
                      </InputLabel>
                      <BootstrapInput
                        {...field}
                        sx={{ width: 1 }}
                        type="text"
                        id="prefectures"
                        size="small"
                        error={!!errors?.prefectures?.message}
                      />
                    </FormControl>
                  )}
                  name="prefectures"
                  control={control}
                />
                {!!errors?.prefectures?.message && (
                  <FormHelperText error id="prefectures_helper">
                    <>{errors?.prefectures?.message}</>
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={4}></Grid>
          <Grid item sm={8}>
            <Controller
              render={({ field }) => (
                <FormControl
                  fullWidth
                  variant="standard"
                  error={!!errors?.municipalities?.message}
                >
                  <InputLabel
                    shrink
                    htmlFor="municipalities"
                    sx={{ paddingY: 1 }}
                  >
                    市区町村以降
                  </InputLabel>
                  <BootstrapInput
                    {...field}
                    sx={{ width: 1 }}
                    type="text"
                    id="municipalities"
                    size="small"
                    error={!!errors?.municipalities?.message}
                  />
                </FormControl>
              )}
              name="municipalities"
              control={control}
            />
            {!!errors?.municipalities?.message && (
              <FormHelperText error id="municipalities_helper">
                <>{errors?.municipalities?.message}</>
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item sm={4}>
            <Typography sx={{ fontSize: "1rem", lineHeight: "2em" }}>
              電話番号
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Controller
              render={({ field }) => (
                <FormControl fullWidth variant="standard">
                  <TextField
                    {...field}
                    fullWidth
                    type="text"
                    size="small"
                    error={!!errors?.phone_number?.message}
                  />
                </FormControl>
              )}
              name="phone_number"
              control={control}
            />
            {!!errors?.phone_number?.message && (
              <FormHelperText error id="phone_number_helper">
                <>{errors?.phone_number?.message}</>
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Fragment>
      {/* end of customer form */}
      {/* Submit button */}
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          type="button"
          onClick={handleBack}
          disabled
          sx={{ mt: 1, mr: 1, color: "#4B4A4A" }}
        >
          戻る
        </Button>
        <ButtonSubmit type={"submit"} sx={{ mt: 1, mr: 1 }}>
          次へ
        </ButtonSubmit>
      </Grid>
    </form>
  );
};

export default AccountForm;
