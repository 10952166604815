import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

interface TableLoaderProps {
  cellsNum: number;
}

const TableLoader: React.FC<TableLoaderProps> = ({ cellsNum }) => {
  return (
    <TableRow>
      {Array.from(Array(cellsNum), (e, index) => (
        <TableCell key={index} component="th" scope="row">
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableLoader;
