import { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAdminApi } from "../../custom-hooks/apis/use-admin-api";
import { useQuery } from "react-query";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import AdminListTable from "./AdminListTable";

const AdminListPage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const { getAdmins, updateAdminRole } = useAdminApi();
  const { isLoading, data, refetch } = useQuery(
    [getAdmins, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getAdmins();
      return;
    }
  );

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ color: "#4B4A4A", fontWeight: 600 }}>
          管理者一覧
        </Typography>
        <ButtonSubmit
          type="button"
          sx={{ fontWeight: 550, px: 1 }}
          onClick={() => navigate("/admin/create")}
        >
          新規追加
        </ButtonSubmit>
      </Grid>
      <AdminListTable
        data={data?.users}
        loading={loading}
        refetch={refetch}
        updateAdminRole={updateAdminRole}
      />
    </Box>
  );
};

export default AdminListPage;
