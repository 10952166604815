import { ChangeEvent, Fragment, MouseEvent, useMemo, useState } from "react";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
} from "@mui/material";
import { OptionType } from "../../models";
import { Search, Delete, Refresh } from "@mui/icons-material";
import BootstrapInput from "../../components/Inputs/BootstrapInput";
import CustomSelect from "../../components/Inputs/CustomSelect";
import TableLoader from "../../components/Loader/TableLoader";

interface NomasseListData {
  id: string;
  name: string;
}

function createData(id: string, name: string): NomasseListData {
  return {
    id,
    name,
  };
}

const filterSelectOptions: readonly OptionType[] = [
  { label: "のまっせID", value: "id" },
  { label: "のまっせ名", value: "name" },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof NomasseListData;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "のまっせID",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "のまっせ名",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof NomasseListData
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof NomasseListData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  search: string;
  searchField: keyof NomasseListData;
  refetch: () => void;
  handleSearchField: (value: keyof NomasseListData) => void;
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDeleteSelectedItems: () => void;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const {
    numSelected,
    search,
    searchField,
    refetch,
    handleSearch,
    handleSearchField,
    handleDeleteSelectedItems,
  } = props;

  const handleChange = (event: any) => {
    handleSearchField(event.target.value);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 1,
          }}
          py={2}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            container
            spacing={2}
          >
            <Grid item xs={12} sm={"auto"}>
              <InputLabel sx={{}}>プロパティ</InputLabel>
            </Grid>

            <Grid item xs={12} sm={"auto"} minWidth={"200px"}>
              <FormControl fullWidth>
                <CustomSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleChange}
                  size="small"
                  defaultValue={
                    filterSelectOptions[
                      filterSelectOptions.findIndex((item: OptionType) => {
                        return String(item.value) === searchField;
                      })
                    ]?.value
                  }
                >
                  {filterSelectOptions.map(
                    (option: OptionType, index: number) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </CustomSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={"auto"}>
              <BootstrapInput
                fullWidth
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                value={search}
                onChange={handleSearch}
                placeholder="プロパティでカスタマーを検索"
              />
            </Grid>
          </Grid>
          <Grid>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => refetch()}
            >
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteSelectedItems}>
            <Delete />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
      )}
    </Toolbar>
  );
}

interface NomasseListTableProps {
  data: NomasseListData[];
  loading: boolean;
  refetch: () => void;
}

const NomasseListTable: React.FC<NomasseListTableProps> = ({
  data,
  loading,
  refetch,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof NomasseListData>("id");
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [totalPage, setTotalPage] = useState<number>(10);
  const [search, setSearch] = useState<string>("");
  const [searchField, setSearchField] = useState<keyof NomasseListData>("id");
  const [rows, setRows] = useState<NomasseListData[]>([
    createData("1", "data 1"),
    createData("2", "data 2"),
    createData("3", "data 3"),
    createData("4", "data 4"),
    createData("5", "data 5"),
    createData("6", "data 6"),
    createData("7", "data 7"),
    createData("8", "data 8"),
    createData("9", "data 9"),
    createData("10", "data 10"),
  ]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof NomasseListData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSelected([]);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;
    setSearch(val);
  };

  const handleSearchField = (value: keyof NomasseListData) => {
    setSearchField(value);
    setSearch("");
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
  };

  const handleDeleteSelectedItems = () => {
    console.log("selected : ", selected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSearchByField = (
    rows: NomasseListData[],
    search: string,
    searchField: keyof NomasseListData
  ) => {
    search = search.trim();
    let result = rows.filter((row) => {
      switch (searchField) {
        case "name":
          return row?.name && row?.name.includes(search);
        default:
          return row?.id && row?.id.includes(search);
      }
    });
    setTotalPage(result.length);
    return result;
  };

  const visibleRows = useMemo(
    () =>
      stableSort(
        handleSearchByField(rows, search, searchField),
        getComparator(order, orderBy)
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage, search, searchField]
  );

  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          search={search}
          searchField={searchField}
          refetch={() => {
            setPage(0);
            refetch();
            setSelected([]);
          }}
          handleSearch={handleSearch}
          handleSearchField={handleSearchField}
          handleDeleteSelectedItems={handleDeleteSelectedItems}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            sx={{ maxWidth: "100%", overflowX: "scroll" }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={visibleRows.length}
            />
            <TableBody>
              {visibleRows?.length > 0 ? (
                <Fragment>
                  {visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return loading ? (
                      <Fragment>
                        <TableLoader key={index} cellsNum={7} />
                      </Fragment>
                    ) : (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row?.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row?.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.name}</TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              ) : (
                <TableRow tabIndex={-1}>
                  <TableCell colSpan={7} align="center">
                    のまっせが見つかりません
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          labelRowsPerPage={"ページあたりの行数:"}
          count={totalPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default NomasseListTable;
