import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import ButtonRegular from "../../Buttons/ButtonRegular";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

interface ModalCustomerConfirmationProps {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  px: 2,
  pt: 4,
};

const ModalCustomerConfirmation: React.FC<ModalCustomerConfirmationProps> = ({
  open,
  handleClose,
  onSubmit,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-description" align="center">
          登録してよろしいですか？
        </Typography>
        <Grid sx={{ mt: 4, mb: 2, display: "flex", justifyContent: "center" }}>
          <ButtonRegular type="button" sx={{ mx: 2 }} onClick={handleClose}>
            戻る
          </ButtonRegular>
          <ButtonSubmit type="button" sx={{ mx: 2 }} onClick={onSubmit}>
            登録する
          </ButtonSubmit>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalCustomerConfirmation;
