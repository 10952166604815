import AwsApi from "./aws-api";
import { AdminFormType } from "../models";

export default class appAdminApi extends AwsApi {
  async createAdmin(params: AdminFormType): Promise<any> {
    return await this.post(
      "admins/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }

  async getAdmins(): Promise<any> {
    return await this.get("admins/index/" + process.env.REACT_APP_ENVNAME);
  }

  async updateAdminRole(adminId: string, newRole: string): Promise<any> {
    return await this.patch("admins/" + process.env.REACT_APP_ENVNAME, {id: adminId, role: newRole});
  }
}
