import { useState } from "react";
import { Box, Container, Grid, Modal, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";

const PaymentCompleted = () => {
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const { state } = useLocation();

  const backApp = () => {
    navigate("/");
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          height: "100vh",
          bgcolor: "#E6E4EB",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Grid
            sx={{
              paddingY: 3,
            }}
          >
            <Typography
              sx={{
                width: 1,
                fontWeight: 550,
                fontSize: "18pt",
              }}
              align="center"
            >
              ページの表示に失敗しました
            </Typography>
          </Grid>

          <Grid
            sx={{
              paddingY: 1,
            }}
          >
            <Typography sx={{ width: 1, fontSize: "12pt" }} align="center">
              御酒飲帳をご利用いただきありがとうございます
            </Typography>
            <Typography sx={{ width: 1, fontSize: "12pt" }} align="center">
              トップページから再度お試しください
            </Typography>
          </Grid>

          <Grid
            sx={{
              paddingY: 3,
            }}
          >
            <ButtonSubmit
              sx={{
                fontSize: "12pt",
                fontWeight: 600,
              }}
              fullWidth
              onClick={() => backApp()}
            >
              トップに戻る
            </ButtonSubmit>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default PaymentCompleted;
