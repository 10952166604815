import { StepLabel, styled } from "@mui/material";

const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  ".Mui-completed .MuiSvgIcon-root": {
    color: "#9f1831",
  },
  ".Mui-active .MuiSvgIcon-root": {
    color: "#9f1831",
  },
}));

export default CustomStepLabel;
