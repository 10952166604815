import { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCustomerApi } from "../../custom-hooks/apis/use-customer-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../global-state/react-query-keys";
import Loader from "../../components/Loader";
import AuthContext from "../../custom-hooks/use-auth-context";
import GlobalDataContext from "../../custom-hooks/use-global-data";
import ButtonSubmit from "../../components/Buttons/ButtonSubmit";
import CustomerListTable from "./CustomerListTable";

const CustomerListPage = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { loading } = useContext(GlobalDataContext);
  const { getCustomers } = useCustomerApi();
  const { isLoading, data, refetch } = useQuery(
    [QueryKeys.customers, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getCustomers();
      return;
    }
  );

  if (isLoading) return <Loader />;
  return (
    <Box>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ color: "#4B4A4A", fontWeight: 600 }}>
          カスタマー一覧
        </Typography>
        <ButtonSubmit
          type="button"
          sx={{ fontWeight: 550, px: 1 }}
          onClick={() => navigate("/customer/create")}
        >
          新規追加
        </ButtonSubmit>
      </Grid>
      <CustomerListTable
        data={data?.customers}
        loading={loading}
        refetch={refetch}
      />
    </Box>
  );
};

export default CustomerListPage;
