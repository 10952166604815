import React from "react";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { LOGO } from "../../assets";
import { Outlet } from "react-router-dom";

const defaultTheme = createTheme();
const AuthLayout = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ bgcolor: "#9f1831", height: "100vh" }}>
        <Grid sx={{ py: 2 }}>
          <Grid sx={{ display: "flex", justifyContent: "center" }}>
            <img src={LOGO} width={100} height={100} />
          </Grid>
          <Typography sx={{ color: "#fff", fontWeight: 550 }} align="center">
            for Admin
          </Typography>
        </Grid>

        <Container component="main" maxWidth="xs" sx={{ bgcolor: "#fff" }}>
          <CssBaseline />
          <Outlet />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default AuthLayout;
