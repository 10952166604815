import { Fragment } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { OptionType, StoreFormType } from "../../../models";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BootstrapInput from "../../Inputs/BootstrapInput";
import ButtonRegular from "../../Buttons/ButtonRegular";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import {
  municipalitiesOptionalValidation,
  postalCodeOptionalValidation,
  prefecturesOptionalValidation,
  storeNameValidation,
  storeSubnameOptionalValidation,
  storeTypeValidation,
} from "../../../utils/validations";

interface StoreFormProps {
  preValues: StoreFormType;
  handleBack: () => void;
  onSubmit: (data: FieldValues) => void;
}

const store_type_options: OptionType[] = [
  {
    label: "酒造",
    value: "酒造",
  },
  {
    label: "酒販店",
    value: "酒販店",
  },
  {
    label: "飲食店",
    value: "飲食店",
  },
  {
    label: "その他",
    value: "その他",
  },
];

const StoreForm: React.FC<StoreFormProps> = ({
  preValues,
  handleBack,
  onSubmit,
}) => {
  const defaultValues: StoreFormType = preValues;

  const schema = Yup.object().shape({
    store_name: storeNameValidation,
    sub_store_name: storeSubnameOptionalValidation,
    store_type: storeTypeValidation,
    store_postal_code: postalCodeOptionalValidation,
    store_prefectures: prefecturesOptionalValidation,
    store_municipalities: municipalitiesOptionalValidation,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const autoFillStoreAddress = () => {
    setValue("store_postal_code", preValues?.postal_code || null);
    setValue("store_prefectures", preValues?.prefectures || null);
    setValue("store_municipalities", preValues?.municipalities || null);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid my={4}>
        <Typography
          sx={{
            fontWeight: 550,
            fontSize: "1.2em",
            color: "#4B4A4A",
          }}
        >
          カスタマー情報
        </Typography>
        <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
          カスタマーに関する情報です。
        </Typography>
      </Grid>
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Grid item sm={4}>
          <Typography
            sx={{ fontSize: "1em", display: "inline-block", lineHeight: "2em" }}
          >
            店舗名
          </Typography>
          <Typography
            sx={{
              fontSize: "1em",
              color: "red",
              display: "inline-block",
              lineHeight: "2em",
            }}
          >
            ※
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  size="small"
                  error={!!errors?.store_name?.message}
                />
              </FormControl>
            )}
            name="store_name"
            control={control}
          />
          {!!errors?.store_name?.message && (
            <FormHelperText error id="store_name_helper">
              <>{errors?.store_name?.message}</>
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Grid item sm={4}>
          <Typography
            sx={{ fontSize: "1em", display: "inline-block", lineHeight: "2em" }}
          >
            サブ店舗名
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth variant="standard">
                <TextField
                  {...field}
                  fullWidth
                  type="text"
                  size="small"
                  error={!!errors?.sub_store_name?.message}
                />
              </FormControl>
            )}
            name="sub_store_name"
            control={control}
          />
          {!!errors?.sub_store_name?.message && (
            <FormHelperText error id="sub_store_name_helper">
              <>{errors?.sub_store_name?.message}</>
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} sx={{ my: 2 }}>
        <Grid item sm={4}>
          <Typography
            sx={{ fontSize: "1em", lineHeight: "2em", display: "inline-block" }}
          >
            店舗形態
          </Typography>
          <Typography
            sx={{
              fontSize: "1em",
              color: "red",
              display: "inline-block",
              lineHeight: "2em",
            }}
          >
            ※
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <FormControl error={!!errors?.store_type?.message} sx={{ width: 1 }}>
            <Controller
              name="store_type"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select {...field} size="small">
                  {store_type_options.map((type: OptionType, index: number) => {
                    return <MenuItem value={type.value}>{type.label}</MenuItem>;
                  })}
                </Select>
              )}
            />
          </FormControl>
          {!!errors?.store_type?.message && (
            <FormHelperText error id="store_type_helper">
              <>{errors?.store_type?.message}</>
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Grid item sm={4}>
          <Typography sx={{ fontSize: "1em", lineHeight: "2em" }}>
            住所
          </Typography>
        </Grid>
        <Grid item sm={8}>
          <Grid mb={2}>
            <ButtonRegular
              type="button"
              variant="contained"
              onClick={autoFillStoreAddress}
            >
              自動入力
            </ButtonRegular>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={5}>
              <Controller
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={!!errors?.store_postal_code?.message}
                  >
                    <InputLabel
                      shrink
                      htmlFor="store_postal_code"
                      sx={{ paddingY: 1 }}
                    >
                      郵便番号
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      type="text"
                      id="store_postal_code"
                      size="small"
                      error={!!errors?.store_postal_code?.message}
                    />
                  </FormControl>
                )}
                name="store_postal_code"
                control={control}
              />
              {!!errors?.store_postal_code?.message && (
                <FormHelperText error id="store_postal_code_helper">
                  <>{errors?.store_postal_code?.message}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid item sm={7}>
              <Controller
                render={({ field }) => (
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={!!errors?.store_prefectures?.message}
                  >
                    <InputLabel
                      shrink
                      htmlFor="store_prefectures"
                      sx={{ paddingY: 1 }}
                    >
                      都道府県
                    </InputLabel>
                    <BootstrapInput
                      {...field}
                      sx={{ width: 1 }}
                      type="text"
                      id="store_prefectures"
                      size="small"
                      error={!!errors?.store_prefectures?.message}
                    />
                  </FormControl>
                )}
                name="store_prefectures"
                control={control}
              />
              {!!errors?.store_prefectures?.message && (
                <FormHelperText error id="store_prefectures_helper">
                  <>{errors?.store_prefectures?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Grid item sm={4}></Grid>
        <Grid item sm={8}>
          <Controller
            render={({ field }) => (
              <FormControl
                fullWidth
                variant="standard"
                error={!!errors?.store_municipalities?.message}
              >
                <InputLabel
                  shrink
                  htmlFor="store_municipalities"
                  sx={{ paddingY: 1 }}
                >
                  市区町村以降
                </InputLabel>
                <BootstrapInput
                  {...field}
                  sx={{ width: 1 }}
                  type="text"
                  id="store_municipalities"
                  size="small"
                  error={!!errors?.store_municipalities?.message}
                />
              </FormControl>
            )}
            name="store_municipalities"
            control={control}
          />
          {!!errors?.store_municipalities?.message && (
            <FormHelperText error id="store_municipalities_helper">
              <>{errors?.store_municipalities?.message}</>
            </FormHelperText>
          )}
        </Grid>
      </Grid>
      {/* Submit button */}
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          type="button"
          onClick={handleBack}
          sx={{ mt: 1, mr: 1, color: "#4B4A4A" }}
        >
          戻る
        </Button>
        <ButtonSubmit type={"submit"} sx={{ mt: 1, mr: 1 }}>
          次へ
        </ButtonSubmit>
      </Grid>
    </form>
  );
};

export default StoreForm;
