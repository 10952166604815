import { useCallback, useContext, useMemo } from "react";
import { AdminFormType } from "../../models";
import GlobalDataContext from "../use-global-data";
import appAdminApi from "../../apis/admin-api";

export const useAdminApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const adminApi = useMemo(
    () => new appAdminApi(process.env.REACT_APP_API_3 || ""),
    []
  );
  // const customerApi2 = useMemo(
  //   () =>
  //     new appAdminApi(
  //       process.env.REACT_APP_API_2 || "",
  //       process.env.REACT_APP_API_KEY || ""
  //     ),
  //   []
  // );

  const createAdmin = useCallback(
    async (params: AdminFormType) => {
      loadingOp.start();

      const response = await adminApi.createAdmin(params);
      loadingOp.finish();
      return response;
    },
    [adminApi]
  );

  const getAdmins = useCallback(async () => {
    loadingOp.start();
    const deviceList = await adminApi.getAdmins();
    loadingOp.finish();
    return deviceList;
  }, [adminApi]);

  const updateAdminRole = useCallback(
    async (adminId: string, newRole: string) => {
      loadingOp.start();
      const response = await adminApi.updateAdminRole(adminId, newRole);
      loadingOp.finish();
      return response;
    },
    [adminApi, loadingOp]
  );

  return {
    createAdmin,
    getAdmins,
    updateAdminRole,
  };
};
