import HomePage from "./Home";
import LoginPage from "./Auth";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import ResetPasswordPage from "./Auth/ResetPassword";
import CustomerListPage from "./CustomerList";
import CustomerCreatePage from "./CustomerCreate";
import UserListPage from "./UserList";
import UserCreatePage from "./UserCreate";
import NomasseListPage from "./NomasseList";
import NomasseCreatePage from "./NomasseCreate";
import AdminListPage from "./AdminList";
import AdminCreatePage from "./AdminCreate"
import SettingsPage from "./Settings";
import NotFoundErrorPage from "./NotFoundError";

export default {
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  CustomerListPage,
  CustomerCreatePage,
  UserListPage,
  UserCreatePage,
  NomasseListPage,
  NomasseCreatePage,
  AdminListPage,
  AdminCreatePage,
  SettingsPage,
  NotFoundErrorPage,
};
