import { useCallback, useContext, useMemo } from "react";
import { CreateCustomerType } from "../../models";
import GlobalDataContext from "../use-global-data";
import appCustomerApi from "../../apis/customer-api";

export const useCustomerApi = () => {
  const { loadingOp } = useContext(GlobalDataContext);

  const customerApi = useMemo(
    () => new appCustomerApi(process.env.REACT_APP_API_1 || ""),
    []
  );
  const customerApi2 = useMemo(
    () =>
      new appCustomerApi(
        process.env.REACT_APP_API_2 || "",
        process.env.REACT_APP_API_KEY || ""
      ),
    []
  );

  const getDevices = useCallback(async () => {
    loadingOp.start();
    const deviceList = await customerApi.getDevices();
    loadingOp.finish();
    return deviceList;
  }, [customerApi]);

  const createCustomer = useCallback(
    async (params: CreateCustomerType) => {
      loadingOp.start();

      const response = await customerApi2.createCustomer(params);
      loadingOp.finish();
      return response;
    },
    [customerApi2]
  );

  const getCustomers = useCallback(async () => {
    loadingOp.start();
    const deviceList = await customerApi2.getCustomers();
    loadingOp.finish();
    return deviceList;
  }, [customerApi2]);

  return {
    getDevices,
    createCustomer,
    getCustomers,
  };
};
