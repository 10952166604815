import pages from "./pages";
import components from "./components";
import { Routes, Route } from "react-router-dom";

const {
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  CustomerListPage,
  CustomerCreatePage,
  UserListPage,
  UserCreatePage,
  NomasseListPage,
  NomasseCreatePage,
  AdminListPage,
  AdminCreatePage,
  SettingsPage,
  NotFoundErrorPage,
} = pages;
const { Layout, AuthLayout } = components;

export const PrivateRoutes = [
  {
    path: "/",
    key: "home-page",
    component: <HomePage />,
  },
  {
    path: "/customer/list",
    key: "customer-list-page",
    component: <CustomerListPage />,
  },
  {
    path: "/customer/create",
    key: "customer-create-page",
    component: <CustomerCreatePage />,
  },
  {
    path: "/user/list",
    key: "user-list-page",
    component: <UserListPage />,
  },
  {
    path: "/user/create",
    key: "user-create-page",
    component: <UserCreatePage />,
  },
  {
    path: "/nomasse/list",
    key: "nomasse-list-page",
    component: <NomasseListPage />,
  },
  {
    path: "/nomasse/create",
    key: "nomasse-create-page",
    component: <NomasseCreatePage />,
  },
  {
    path: "/admin/list",
    key: "admin-list-page",
    component: <AdminListPage />,
  },
  {
    path: "/admin/create",
    key: "admin-create-page",
    component: <AdminCreatePage />,
  },
  {
    path: "/settings",
    key: "settings-page",
    component: <SettingsPage />,
  },
];

export const AuthRoutes = [
  {
    path: "/login",
    key: "login-page",
    component: <LoginPage />,
  },
  {
    path: "/forgot-password",
    key: "forgot-password-page",
    component: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    key: "reset-password-page",
    component: <ResetPasswordPage />,
  },
];

export const PublicRoutes = [
  {
    path: "*",
    key: "not-found",
    component: <NotFoundErrorPage />,
  },
];

const Router = () => {
  return (
    <Routes>
      <Route element={<Layout />} key="Layout">
        {PrivateRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
      <Route element={<AuthLayout />} key="AuthLayout">
        {AuthRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
      <Route key="PublicLayout">
        {PublicRoutes.map(({ path, component, key }) => (
          <Route path={path} element={component} key={key} />
        ))}
      </Route>
    </Routes>
  );
};
export default Router;
