import AwsApi from "./aws-api";
import { CreateCustomerType } from "../models";

export default class appCustomerApi extends AwsApi {
  async getDevices(): Promise<any> {
    return await this.get("devices/" + process.env.REACT_APP_ENVNAME);
  }

  async createCustomer(params: CreateCustomerType): Promise<any> {
    return await this.post(
      "customers/" + process.env.REACT_APP_ENVNAME,
      params
    );
  }

  async getCustomers(): Promise<any> {
    return await this.get("customers/" + process.env.REACT_APP_ENVNAME);
  }
}
