import { Fragment } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonSubmit from "../../Buttons/ButtonSubmit";

interface CustomSettingsProps {
  handleBack: () => void;
  onSubmit: (data: FieldValues) => void;
}

const CustomSettingsForm: React.FC<CustomSettingsProps> = ({
  handleBack,
  onSubmit,
}) => {
  const schema = Yup.object().shape({});
  const defaultValues = {};
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* title of form */}
      <Grid>
        <Typography
          sx={{ fontWeight: 550, fontSize: "1.2em", color: "#4B4A4A" }}
        >
          カスタム設定
        </Typography>
        <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
          その他、カスタマイズ設定が必要な場合は設定してください。
        </Typography>
      </Grid>
      <Grid my={2}>
        <Typography sx={{ color: "#4B4A4A" }}>
          現在はカスタマイズ設定がありません。
        </Typography>
      </Grid>
      {/* Submit button */}
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          type="button"
          onClick={handleBack}
          sx={{ mt: 1, mr: 1, color: "#4B4A4A" }}
        >
          戻る
        </Button>
        <ButtonSubmit type={"submit"} sx={{ mt: 1, mr: 1 }}>
          次へ
        </ButtonSubmit>
      </Grid>
    </form>
  );
};

export default CustomSettingsForm;
