import { Dispatch, Fragment, SetStateAction } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { AdminFormType, OptionType } from "../../../models";
import {
  adminRoleValidation,
  adminNameValidation,
  emailValidation,
} from "../../../utils/validations";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import CustomSelect from "../../Inputs/CustomSelect";

interface AdminFormProps {
  formValues: Partial<AdminFormType>;
  onSubmit: (data: FieldValues) => void;
  preValues: AdminFormType;
  roleOptions: readonly OptionType[];
}

const AdminForm: React.FC<AdminFormProps> = ({
  roleOptions,
  preValues,
  formValues,
  onSubmit,
}) => {
  const schema = Yup.object().shape({
    name: adminNameValidation,
    email: emailValidation,
    role: adminRoleValidation,
  });

  const defaultValues: AdminFormType = preValues;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fragment>
        <Typography
          sx={{ fontWeight: 550, fontSize: "1.2em", color: "#4B4A4A" }}
        >
          アカウント情報
        </Typography>
        <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
          管理者に共有するアカウントの情報です。パスワードは自動生成されます。
        </Typography>
        <Grid my={4}>
          <Grid container spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <Typography sx={{ fontSize: "1em", lineHeight: "2em" }}>
                管理者名
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      size="small"
                      error={!!errors?.name?.message}
                    />
                  </FormControl>
                )}
                name="name"
                control={control}
              />
              {!!errors?.name?.message && (
                <FormHelperText error id="customer_name_helper">
                  <>{errors?.name?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <Typography sx={{ fontSize: "1em", lineHeight: "2em" }}>
                メールアドレス
              </Typography>
            </Grid>
            <Grid item sm={8}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <TextField
                      {...field}
                      fullWidth
                      type="email"
                      size="small"
                      error={!!errors?.email?.message}
                    />
                  </FormControl>
                )}
                name="email"
                control={control}
              />
              {!!errors?.email?.message && (
                <FormHelperText error id="customer_email_helper">
                  <>{errors?.email?.message}</>
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <Typography sx={{ fontSize: "1em", lineHeight: "2em" }}>
                権限
              </Typography>
            </Grid>
            <Grid item xs={12} sm={"auto"} minWidth={"200px"}>
              <Controller
                render={({ field }) => (
                  <FormControl fullWidth>
                    <CustomSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      {...field}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      size="small"
                      value={field.value}
                    >
                      {roleOptions.map((option: OptionType, index: number) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                )}
                name="role"
                control={control}
              />
              {!!errors?.role?.message && (
                <FormHelperText error id="role_helper">
                  <>{errors?.role?.message}</>
                </FormHelperText>
              )}
              {<div>{errors?.role?.message}</div>}
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <ButtonSubmit type={"submit"} sx={{ mt: 1, mr: 1 }}>
          {"登録する"}
        </ButtonSubmit>
      </Grid>
    </form>
  );
};

export default AdminForm;
