import { Button, ButtonProps, styled } from "@mui/material";

const ButtonRegular = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "#4B4A4A",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "&:disabled": {
    opacity: 0.5,
    color: "#4B4A4A",
  },
}));

export default ButtonRegular;
