import { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import AdminForm from "../../components/Forms/CustomerForm/AdminForm";
import ModalCustomerConfirmation from "../../components/Modals/ConfirmationCustomerModal";
import { AdminFormType, OptionType } from "../../models";
import { useAdminApi } from "../../custom-hooks/apis/use-admin-api";
import { useMutation } from "react-query";
import { toastError, toastSuccess } from "../../utils/toaster";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";

const roleOptions: readonly OptionType[] = [
  { label: "管理者", value: "admin" },
  { label: "権限2", value: "maintain" },
  { label: "権限3", value: "management" },
  { label: "権限4", value: "read" },
];

const DEFAULT_ROLE = roleOptions[3]["value"];

const AdminCreate = () => {
  const navigate = useNavigate();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => setConfirmationModalOpen(false);

  const { createAdmin } = useAdminApi();

  const createAdminMutation = useMutation(
    (requestBody: AdminFormType) => createAdmin(requestBody),
    {
      onSuccess: async (data: any) => {
        setIsLoading(false);
        toastSuccess("新しいアドミンの作成に成功");
        navigate("/admin/list");
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (
          String(error?.message)
            .toLowerCase()
            .includes("network error")
        )
          errMessage =
            "新しいアドミンを作成できませんでした。再試行してください！";

        toastError(errMessage);
      },
    },
  );

  const defaultValues = {
    email: "",
    name: "",
    role: DEFAULT_ROLE,
  };

  const {
    watch,
    setValue,
  } = useForm({
    defaultValues,
  });

  const formValues = watch();

  const onSubmit = (params: FieldValues) => {
    setValue("email", params.email);
    setValue("name", params.name);
    setValue("role", params.role);

    setConfirmationModalOpen(true);
  };

  if (isLoading) return <Loader />;
  return (
    <Box>
      {/* Title section */}
      <Grid sx={{ mb: 2 }}>
        <Typography sx={{ color: "#4B4A4A", fontWeight: 600 }}>
          Admin - 新規登録
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {/* Body section */}
          <Grid item sm={9}>
            <Box sx={{ width: "85%" }}>
              <AdminForm
                formValues={formValues}
                onSubmit={onSubmit}
                preValues={{
                  name: formValues.name || "",
                  email: formValues.email || "",
                  role: formValues.role || DEFAULT_ROLE,
                }}
                roleOptions={roleOptions}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {confirmationModalOpen && (
        <ModalCustomerConfirmation
          open={confirmationModalOpen}
          handleClose={handleClose}
          onSubmit={() => {
            setIsLoading(true);
            let params: AdminFormType = {
              email: formValues.email,
              name: formValues.name,
              role: formValues.role,
            };
            createAdminMutation.mutate(params);
            handleClose();
          }}
        />
      )}
    </Box>
  );
};

export default AdminCreate;
