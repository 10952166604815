import { Fragment, useContext } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useCustomerApi } from "../../../custom-hooks/apis/use-customer-api";
import { useQuery } from "react-query";
import { QueryKeys } from "../../../global-state/react-query-keys";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, useForm } from "react-hook-form";
import AuthContext from "../../../custom-hooks/use-auth-context";
import ButtonSubmit from "../../Buttons/ButtonSubmit";
import Loader from "../../Loader";

interface NomasseSelectionFormProps {
  preValues: string[];
  handleBack: () => void;
  onSubmit: (data: FieldValues) => void;
}

const NomasseSelectionForm: React.FC<NomasseSelectionFormProps> = ({
  preValues,
  handleBack,
  onSubmit,
}) => {
  const authCtx = useContext(AuthContext);
  const { getDevices } = useCustomerApi();
  const { isLoading, data, error } = useQuery(
    [QueryKeys.devices, authCtx.user?.signInUserSession.idToken],
    () => {
      if (authCtx.user?.signInUserSession.idToken) return getDevices();
      return;
    }
  );

  const defaultValues = {
    nomasses: preValues,
  };

  const schema = Yup.object().shape({
    nomasses: Yup.array().test(
      "at-least-one",
      "少なくとも１つのご契約のまっせを選択する必要があります",
      (value) => {
        return Array.isArray(value) && value.length > 0;
      }
    ),
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const nomasses = watch("nomasses");

  const handleToggle = (value: string) => () => {
    if (nomasses.includes(value)) {
      setValue(
        "nomasses",
        nomasses.filter((val: string) => val !== value)
      );
    } else {
      setValue("nomasses", [...nomasses, value]);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* title of form */}
      <Grid>
        <Typography
          sx={{ fontWeight: 550, fontSize: "1.2em", color: "#4B4A4A" }}
        >
          ご契約のまっせ
        </Typography>
        <Typography sx={{ color: "#4B4A4A", fontSize: "0.9em" }}>
          カスタマーが利用するのまっせを選択してください。
        </Typography>
      </Grid>
      {/* table of selection */}
      <Grid my={2}>
        {isLoading ? (
          <Loader />
        ) : data?.length > 0 ? (
          <Fragment>
            <Typography
              sx={{ color: "#4B4A4A", fontSize: "0.8em", fontWeight: 550 }}
              align="right"
            >
              {nomasses.length || 0}台
            </Typography>
            <List
              sx={{
                width: "100%",
                maxHeight: "450px",
                overflowY: "scroll",
                bgcolor: "background.paper",
                border: 1,
              }}
            >
              {data.map((option: any, index: number) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                  <ListItem
                    key={index}
                    disablePadding
                    sx={{ borderBottom: index < data?.length - 1 ? 1 : 0 }}
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={handleToggle(option?.thingName)}
                      dense
                    >
                      <ListItemIcon>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={option?.thingName}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                              checked={
                                nomasses && nomasses.includes(option?.thingName)
                              }
                            />
                          }
                          label={<></>}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={
                          <Fragment>
                            <Typography
                              sx={{
                                fontSize: "0.9em",
                                display: "inline-block",
                                fontWeight: 550,
                              }}
                            >
                              {option?.thingName}
                            </Typography>
                            &nbsp;&nbsp;
                            <Typography
                              sx={{
                                fontSize: "0.9em",
                                display: "inline-block",
                                color: "#989191",
                              }}
                            >
                              {option.desc || ""}
                            </Typography>
                          </Fragment>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            {!!errors?.nomasses?.message && (
              <FormHelperText error id="nomasses_helper">
                <>{errors?.nomasses?.message}</>
              </FormHelperText>
            )}
          </Fragment>
        ) : (
          <Typography
            sx={{
              fontSize: "0.9em",
              display: "inline-block",
            }}
          >
            ご契約のまっせがありませんでした。
          </Typography>
        )}
      </Grid>
      {/* Submit button */}
      <Grid sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          type="button"
          onClick={handleBack}
          sx={{ mt: 1, mr: 1, color: "#4B4A4A" }}
        >
          戻る
        </Button>
        <ButtonSubmit type={"submit"} sx={{ mt: 1, mr: 1 }}>
          次へ
        </ButtonSubmit>
      </Grid>
    </form>
  );
};

export default NomasseSelectionForm;
