import { Auth } from "aws-amplify";
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}
class AwsApi {
  protected client: AxiosInstance;
  protected url: string;
  protected api_key: string | null;
  protected ticket_id?: string;

  constructor(url: string, api_key?: string | null, ticket_id?: string) {
    this.url = url;
    this.api_key = api_key ? api_key : null;
    this.ticket_id = ticket_id;

    const idToken = localStorage.getItem("idToken");
    this.client = axios.create({
      baseURL: this.url,
      headers:
        this.api_key && this.ticket_id
          ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
              "x-api-key": this.api_key,
              ticket_id: this.ticket_id,
            }
          : this.api_key && !this.ticket_id
          ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
              "x-api-key": this.api_key,
            }
          : {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.client.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = async (error: AxiosError) => {
    if (error?.code === "ERR_NETWORK") {
      try {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        const session = await Auth.currentSession();

        const idTokenExpired =
          new Date(session.getIdToken().getExpiration()) < new Date();

        if (idTokenExpired) {
          const isValidRefreshToken = session.isValid();
          if (isValidRefreshToken) {
            console.log("refresh token is valid");
            user.refreshSession(
              user.signInUserSession.refreshToken,
              async (err: any, session: any) => {
                if (err) {
                  console.log("error : ", err);
                  throw new Error(err);
                } else {
                  const idToken = session.getIdToken().getJwtToken();
                  localStorage.setItem("idToken", idToken);
                }
              }
            );
          }
        }
      } catch (error) {
        if (error === "The user is not authenticated") {
          const baseUrl = window.location.origin;
          let redirect_url =
            baseUrl + "/login/quick?login_redirect_url=" + window.location.href;

          await Auth.signOut();
          localStorage.removeItem("idToken");

          window.location.href = redirect_url;
        }
      }
    }
    return Promise.reject(error);
  };

  protected get get() {
    const idToken = localStorage.getItem("idToken");
    this.client.defaults.headers["Authorization"] = `Bearer ${idToken}`;
    return this.client.get;
  }

  protected get post() {
    const idToken = localStorage.getItem("idToken");
    this.client.defaults.headers["Authorization"] = `Bearer ${idToken}`;
    return this.client.post;
  }

  protected get put() {
    const idToken = localStorage.getItem("idToken");
    this.client.defaults.headers["Authorization"] = `Bearer ${idToken}`;
    return this.client.put;
  }
  
  protected get patch() {
    const idToken = localStorage.getItem("idToken");
    this.client.defaults.headers["Authorization"] = `Bearer ${idToken}`;
    return this.client.patch;
  }

  protected get delete() {
    const idToken = localStorage.getItem("idToken");
    this.client.defaults.headers["Authorization"] = `Bearer ${idToken}`;
    return this.client.delete;
  }
}

export default AwsApi;
