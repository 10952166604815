import { Fragment } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StoreIcon from "@mui/icons-material/Store";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from "@mui/icons-material/Settings";
import { Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface MenuItemsProps {
  open: boolean;
}

const MenuItems: React.FC<MenuItemsProps> = ({ open }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <ListItemButton onClick={() => navigate("/customer/list")}>
        <ListItemIcon sx={{ color: "#fff", flexDirection: "column" }}>
          <StoreIcon sx={{ml: 1}}/>
          {!open && (
            <Typography  sx={{ fontSize: "10px", ml:"9px"  }}>
              店舗
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText
          primary={<Typography sx={{ fontSize: "1rem" }}>店舗</Typography>}
        />
      </ListItemButton>
      {open && (
        <Fragment>
          <Divider />
          <ListItemButton onClick={() => navigate("/customer/list")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  カスタマー覧
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/customer/create")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  新規カスタマー/店舗登録
                </Typography>
              }
            />
          </ListItemButton>
        </Fragment>
      )}
      <ListItemButton onClick={() => navigate("/user/list")}>
        <ListItemIcon sx={{ color: "#fff", flexDirection: "column" }}>
          <PersonIcon  sx={{ml: 1}}/>
          {!open && (
            <Typography  sx={{ fontSize: "10px" }}>
              ユーザー
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText
          primary={<Typography sx={{ fontSize: "1rem" }}>ユーザー</Typography>}
        />
      </ListItemButton>
      {open && (
        <Fragment>
          <Divider />
          <ListItemButton onClick={() => navigate("/user/list")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  コンシューマーー覧
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/user/create")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  新規コンシューマー登録
                </Typography>
              }
            />
          </ListItemButton>
        </Fragment>
      )}
      <ListItemButton onClick={() => navigate("/nomasse/list")}>
        <ListItemIcon sx={{ color: "#fff", flexDirection: "column" }}>
          <PhoneIphoneIcon  sx={{ml: 1}}/>
          {!open && (
            <Typography  sx={{ fontSize: "10px" }}>
              のまっせ
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText
          primary={<Typography sx={{ fontSize: "1rem" }}>のまっせ</Typography>}
        />
      </ListItemButton>
      {open && (
        <Fragment>
          <Divider />
          <ListItemButton onClick={() => navigate("/nomasse/list")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  のまっせ一覧
                </Typography>
              }
            />
          </ListItemButton>
          <ListItemButton onClick={() => navigate("/nomasse/create")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  新規のまっせ登録
                </Typography>
              }
            />
          </ListItemButton>
        </Fragment>
      )}
      <ListItemButton onClick={() => navigate("/admin/list")}>
        <ListItemIcon sx={{ color: "#fff", flexDirection: "column" }}>
          <ManageAccountsIcon sx={{ml: 1}}/>
          {!open && (
            <Typography  sx={{ fontSize: "10px", ml:"4px"  }}>
              管理者
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText
          primary={<Typography sx={{ fontSize: "1rem" }}>管理者</Typography>}
        />
      </ListItemButton>
      {open && (
        <Fragment>
          <Divider />
          <ListItemButton onClick={() => navigate("/admin/list")}>
            <ListItemIcon></ListItemIcon>
            <ListItemText
              primary={
                <Typography sx={{ fontSize: "0.8rem" }}>
                  管理者一覧
                </Typography>
              }
            />
          </ListItemButton>
        </Fragment>
      )}
      <ListItemButton onClick={() => navigate("/settings")}>
        <ListItemIcon sx={{ color: "#fff", flexDirection: "column" }}>
          <SettingsIcon  sx={{ml: 1}}/>
          {!open && (
            <Typography  sx={{ fontSize: "10px", ml:"9px"  }}>
              設定
            </Typography>
          )}
        </ListItemIcon>
        <ListItemText
          primary={<Typography sx={{ fontSize: "1rem" }}>設定</Typography>}
        />
      </ListItemButton>
    </Fragment>
  );
};

export default MenuItems;
