import { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Step,
  Stepper,
  Typography,
} from "@mui/material";
import { FieldValues, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  contactPersonNameValidation,
  customerNameValidation,
  emailValidation,
  municipalitiesOptionalValidation,
  municipalitiesValidation,
  phoneNumberValidation,
  postalCodeOptionalValidation,
  postalCodeValidation,
  prefecturesOptionalValidation,
  prefecturesValidation,
  storeNameValidation,
  storeSubnameOptionalValidation,
  storeTypeOptionalValidation,
} from "../../utils/validations";
import CustomStepLabel from "./CustomStepLabel";
import AccountForm from "../../components/Forms/CustomerForm/AccountForm";
import StoreForm from "../../components/Forms/CustomerForm/StoreForm";
import NomasseSelectionForm from "../../components/Forms/CustomerForm/NomasseSelectionForm";
import CustomSettingsForm from "../../components/Forms/CustomerForm/CustomSettingsForm";
import ConfirmationForm from "../../components/Forms/CustomerForm/ConfirmationForm";
import ModalCustomerConfirmation from "../../components/Modals/ConfirmationCustomerModal";
import { CreateCustomerType, CustomerType } from "../../models";
import { useCustomerApi } from "../../custom-hooks/apis/use-customer-api";
import { useMutation } from "react-query";
import { toastError, toastSuccess } from "../../utils/toaster";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../custom-hooks/use-auth-context";
import Loader from "../../components/Loader";

const steps = [
  {
    label: "STEP 1",
    description: `アカウント、カスタマー情報を設定`,
  },
  {
    label: "STEP 2",
    description: "店舗情報を設定",
  },
  {
    label: "STEP 3",
    description: `ご契約のまっせを紐付ける`,
  },
  {
    label: "STEP 4",
    description: `カスタム設定を行う（上級設定）`,
  },
  {
    label: "STEP 5",
    description: `確認及び作成`,
  },
];

const CustomerCreate = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [maxStep, setMaxStep] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleClose = () => setConfirmationModalOpen(false);

  const { createCustomer } = useCustomerApi();

  const createCustomerMutation = useMutation(
    (requestBody: CreateCustomerType) => createCustomer(requestBody),
    {
      onSuccess: async (data: any) => {
        toastSuccess("新しいカスタマーの作成に成功");
        navigate("/customer/list");
      },
      onError: async (error: any) => {
        let errMessage =
          error?.response?.data?.message || "予期せぬエラーが発生しました！";
        if (String(error?.message).toLowerCase().includes("network error"))
          errMessage =
            "新しいカスタマーを作成できませんでした。再試行してください！";

        toastError(errMessage);
      },
    }
  );

  const schema = Yup.object().shape({
    activeStep: Yup.number(),
    // start of step 1
    customer_name: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? customerNameValidation
        : Yup.string().trim().nullable().optional();
    }),
    customer_email: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? emailValidation
        : Yup.string().trim().nullable().optional();
    }),
    contact_person_name: Yup.number().when(
      "activeStep",
      ([activeStep], schema) => {
        return activeStep === 4
          ? contactPersonNameValidation
          : Yup.string().trim().nullable().optional();
      }
    ),
    postal_code: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? postalCodeValidation
        : Yup.string().trim().nullable().optional();
    }),
    prefectures: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? prefecturesValidation
        : Yup.string().trim().nullable().optional();
    }),
    municipalities: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? municipalitiesValidation
        : Yup.string().trim().nullable().optional();
    }),
    phone_number: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? phoneNumberValidation
        : Yup.string().trim().nullable().optional();
    }),
    // start of step 2
    store_name: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? storeNameValidation
        : Yup.string().trim().nullable().optional();
    }),
    sub_store_name: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? storeSubnameOptionalValidation
        : Yup.string().trim().nullable().optional();
    }),
    store_type: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? storeTypeOptionalValidation
        : Yup.string().trim().nullable().optional();
    }),
    store_postal_code: Yup.number().when(
      "activeStep",
      ([activeStep], schema) => {
        return activeStep === 4
          ? postalCodeOptionalValidation
          : Yup.string().trim().nullable().optional();
      }
    ),
    store_prefectures: Yup.number().when(
      "activeStep",
      ([activeStep], schema) => {
        return activeStep === 4
          ? prefecturesOptionalValidation
          : Yup.string().trim().nullable().optional();
      }
    ),
    store_municipalities: Yup.number().when(
      "activeStep",
      ([activeStep], schema) => {
        return activeStep === 4
          ? municipalitiesOptionalValidation
          : Yup.string().trim().nullable().optional();
      }
    ),
    // start of step 3
    nomasses: Yup.number().when("activeStep", ([activeStep], schema) => {
      return activeStep === 4
        ? Yup.array().test(
            "at-least-one",
            "少なくとも１つのご契約のまっせを選択する必要があります",
            (value) => {
              return Array.isArray(value) && value.length > 0;
            }
          )
        : Yup.array().nullable().optional();
    }),
  });

  const defaultValues: CustomerType = {
    activeStep: 0,
    customer_name: "",
    customer_email: "",
    contact_person_name: "",
    postal_code: "",
    prefectures: "",
    municipalities: "",
    phone_number: "",
    store_name: "",
    sub_store_name: null,
    store_type: null,
    store_postal_code: null,
    store_prefectures: null,
    store_municipalities: null,
    nomasses: [],
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const formValues = watch();

  useEffect(() => {
    if (formValues.activeStep > maxStep) {
      setMaxStep(formValues.activeStep);
    }
  }, [formValues.activeStep]);

  const handleBack = () => {
    setValue("activeStep", formValues.activeStep - 1);
  };

  const onSubmit = () => {
    setConfirmationModalOpen(true);
  };

  const redirectStep = (step: number) => {
    if (step <= maxStep) setValue("activeStep", step);
  };

  const getStepperStyle = (index: number) => {
    let css = {};
    if (formValues.activeStep === index) {
      css = {
        fontWeight: 550,
      };
    }
    if (index <= maxStep) {
      css = {
        ...css,
        cursor: "pointer",
        textDecoration: "underline",
        textUnderlineOffset: "5px",
      };
    }
    return css;
  };

  const handleSubmitAccountForm = (params: FieldValues) => {
    setValue("customer_name", params?.customer_name);
    setValue("customer_email", params?.customer_email);
    setValue("contact_person_name", params?.contact_person_name);
    setValue("postal_code", params?.postal_code);
    setValue("prefectures", params?.prefectures);
    setValue("municipalities", params?.municipalities);
    setValue("phone_number", params?.phone_number);

    setValue("activeStep", formValues.activeStep + 1);
  };

  const handleSubmitStoreForm = (params: FieldValues) => {
    setValue("store_name", params?.store_name);
    setValue("sub_store_name", params?.sub_store_name);
    setValue("store_type", params?.store_type);
    setValue("store_postal_code", params?.store_postal_code);
    setValue("store_prefectures", params?.store_prefectures);
    setValue("store_municipalities", params?.store_municipalities);

    setValue("activeStep", formValues.activeStep + 1);
  };

  const handleSubmitNomasseForm = (params: FieldValues) => {
    setValue("nomasses", params?.nomasses);

    setValue("activeStep", formValues.activeStep + 1);
  };

  const handleSubmitCustomSettingForm = () => {
    setValue("activeStep", formValues.activeStep + 1);
  };

  return (
    <Box>
      {/* Title section */}
      <Grid sx={{ mb: 2 }}>
        {/* <Typography sx={{ fontWeight: 550 }} component="h1" variant="h6">
          Customer - 新規登録
        </Typography> */}
        <Typography sx={{ color: "#4B4A4A", fontWeight: 600 }}>
          Customer - 新規登録
        </Typography>
      </Grid>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {/* Body section */}
          <Grid item sm={9}>
            <Box sx={{ width: "85%" }}>
              {formValues.activeStep === 0 && (
                <Fragment>
                  <AccountForm
                    preValues={{
                      customer_name: formValues.customer_name || "",
                      customer_email: formValues.customer_email || "",
                      contact_person_name: formValues.contact_person_name || "",
                      postal_code: formValues.postal_code || "",
                      prefectures: formValues.prefectures || "",
                      municipalities: formValues.municipalities || "",
                      phone_number: formValues.phone_number || "",
                    }}
                    handleBack={handleBack}
                    onSubmit={handleSubmitAccountForm}
                  />
                </Fragment>
              )}
              {formValues.activeStep === 1 && (
                <StoreForm
                  preValues={{
                    store_name: formValues.store_name || "",
                    sub_store_name: formValues.sub_store_name || null,
                    store_type: formValues.store_type || null,
                    store_postal_code: formValues.store_postal_code || null,
                    store_prefectures: formValues.store_prefectures || null,
                    store_municipalities:
                      formValues.store_municipalities || null,
                    postal_code: formValues.postal_code,
                    prefectures: formValues.prefectures,
                    municipalities: formValues.municipalities,
                  }}
                  handleBack={handleBack}
                  onSubmit={handleSubmitStoreForm}
                />
              )}
              {formValues.activeStep === 2 && (
                <NomasseSelectionForm
                  preValues={formValues?.nomasses ? formValues?.nomasses : []}
                  handleBack={handleBack}
                  onSubmit={handleSubmitNomasseForm}
                />
              )}
              {formValues.activeStep === 3 && (
                <CustomSettingsForm
                  handleBack={handleBack}
                  onSubmit={handleSubmitCustomSettingForm}
                />
              )}
              {formValues.activeStep === 4 && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ConfirmationForm
                    formValues={formValues}
                    errors={errors}
                    handleBack={handleBack}
                  />
                </form>
              )}

              {/* next and previous button of stepper */}
              {/* <Grid sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    type="button"
                    disabled={formValues.activeStep === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, color: "#4B4A4A" }}
                  >
                    戻る
                  </Button>
                  <ButtonSubmit type={"submit"} sx={{ mt: 1, mr: 1 }}>
                    {+formValues.activeStep === steps.length - 1
                      ? "登録する"
                      : "次へ"}
                  </ButtonSubmit>
                </Grid> */}
            </Box>
          </Grid>
          {/* Stepper section */}
          {authCtx.userDevice !== "mobile" && (
            <Grid item sm={3}>
              <Stepper
                activeStep={formValues.activeStep}
                orientation="vertical"
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <CustomStepLabel
                      onClick={() => redirectStep(index)}
                      optional={
                        <Typography
                          variant="caption"
                          sx={getStepperStyle(index)}
                        >
                          {step.description}
                        </Typography>
                      }
                    >
                      <Typography sx={getStepperStyle(index)}>
                        {step.label}
                      </Typography>
                    </CustomStepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          )}
        </Grid>
      </Paper>
      {confirmationModalOpen && (
        <ModalCustomerConfirmation
          open={confirmationModalOpen}
          handleClose={handleClose}
          onSubmit={() => {
            let params: CreateCustomerType = {
              contact_person_name: formValues.contact_person_name,
              customer_email: formValues.customer_email,
              customer_name: formValues.customer_name,
              municipalities: formValues.municipalities,
              nomasses: formValues.nomasses,
              phone_number: formValues.phone_number,
              postal_code: formValues.postal_code,
              prefectures: formValues.prefectures,
              store_municipalities: formValues.store_municipalities || "",
              store_name: formValues.store_name,
              store_postal_code: formValues.store_postal_code || null,
              store_prefectures: formValues.store_prefectures || "",
              store_type: formValues.store_type || "",
              sub_store_name: formValues.sub_store_name || "",
            };
            createCustomerMutation.mutate(params);
            handleClose();
          }}
        />
      )}
      {createCustomerMutation.isLoading && <Loader />}
    </Box>
  );
};

export default CustomerCreate;
