import { useContext, useEffect } from "react";
import AuthContext from "./custom-hooks/use-auth-context";
import Router from "./routes";
import moment from "moment";
import "moment/locale/ja";

const App = () => {
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    authCtx.assessLoggedInState();
    moment.locale("ja");
  }, []);

  return <Router />;
};

export default App;
